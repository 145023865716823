<div class="container">
  <p style="color:black; " translate>compliance.mainText</p>

  <p *ngIf="lang === 'tr'">
    <em>
      <a href="../../../assets/pdf/nil-kiymetli-madenler-musteri-kabul-politikasi.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.customer
        </span>
      </a>
    </em>
  </p>
  <p *ngIf="lang === 'eng'">
    <em>
      <a href="../../../assets/pdf/nil-kiymetli-madenler-musteri-kabul-politikasi.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.customer
        </span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/responsible-gold-guidance.pdf" target="_blank">
        <span style="color:#9d8b54" translate>
          compliance.lbma
        </span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/GoldSupplement.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.oecd</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/Conflict_Free_Gold_Standard_English.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.word</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/Nil-KYC-FORM.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.kyc</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="" target="_blank">
        <span style="color:#9d8b54" translate>compliance.id</span>
      </a>
    </em>
  </p>
  <p *ngIf="lang === 'tr'">
    <em>
      <a href="../../../assets/pdf/bagimsiz-denetim-raporu.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.auditors</span>
      </a>
    </em>
  </p>
  <p *ngIf="lang === 'eng'">
    <em>
      <a href="../../../assets/pdf/eng/2020-INDEPENDENT-AUDITORS-REPORT.PDF" target="_blank">
        <span style="color:#9d8b54" translate>compliance.auditors</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/İÇ KONTROL SİSTEMİ VE TEDARİK ZİNCİRİ UYUM PROGRAMI.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.long_text</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/KVKK Aydınlatma Metni.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.kvkk</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/ÇERÇEVE SÖZLEŞME Nil.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.environment_text</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/Veri Sahibi Basvuru Formu.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.data_text</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/Çerez Aydınlatma Metni .pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.cookie_text</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/Ek 1 Müşteri Kabul Beyanı .pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.ek1</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/Ek 2 Müşteri Tanı Formu .pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.ek2</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/Ek 3 Müşteri Yetki Belgesi.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.ek3</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/Ek 4 GEREKEN EVRAKLAR.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.ek4</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/bagımsız.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.guvence</span>
      </a>
    </em>
  </p>


  <p>
    <em>
      <a href="../../../assets/pdf/yonetimrapor.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.yonetim</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/bagimsizguvence.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.bagimsiz</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/tedarikzinciri.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.tedarik</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/bagimsizguvence2024.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.bagimsiz2024</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/bist_yonetim_2023.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.bist2024</span>
      </a>
    </em>
  </p>
</div>