<section>
  <div class="container">
    <div class="contactInfo">
      <div>
        <h2 translate>contact.contactUs</h2>
        <ul class="info">
          <li>
            <span><img src="../../../assets/img/location.png" alt="location"></span>
            <span>MOLLA FENARİ MAH. ÇUHACI HANI SK. ÇUHACI HAN NO: 14 İÇ KAPI NO: Z30 FATİH / İSTANBUL</span>
          </li>
          <li>
            <span><img src="../../../assets/img/mail.png"></span>
            <span>mail@nilkiymetlimadenler.com</span>
          </li>
          <li>
            <span><img src="../../../assets/img/call.png"></span>
            <span>+90 212 520 3417</span>
          </li>
          <li>
            <span><img src="../../../assets/img/call.png"></span>
            <span>+90 212 513 5657</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="contactMap">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d376.33219961453483!2d28.96968!3d41.010867!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab993fc8f8dc5%3A0xa3490dee07d9aee9!2zQmV5YXrEsXQsIMOHdWhhY8SxIEhhbiBTay4sIDM0MTI2IEZhdGloL8Swc3RhbmJ1bA!5e0!3m2!1str!2str!4v1719818756745!5m2!1str!2str"
        width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  </div>
</section>