<div class="activities-cont">
  <div class="row">
    <div class="col">
      <h1 translate>activities.title</h1>
      <p translate>activities.text</p>
      <a class="btn" type="button" href="tel:02125203417" translate> activities.button </a>
      <a class="btn" type="button" href="tel:02125135657" translate> activities.button </a>

    </div>
    <div class="col card-col">
      <!--<div class="card"> </div>-->
    </div>
  </div>
</div>
