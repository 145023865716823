<div class="container">
  <table class="tg" style="table-layout: fixed; width: 100%">
    <colgroup>
      <col style="width: 100px">
      <col style="width: 100%">
    </colgroup>
    <thead>
    <tr>
      <th class="tg-1wig" translate>infoSociety.companyType</th>
      <th class="tg-6t3r">A.Ş.</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td class="tg-1wig" translate>infoSociety.registrationNo</td>
      <td class="tg-6t3r">6311113004</td>
    </tr>
    <tr>
      <td class="tg-1wig" translate>infoSociety.commercialTitle</td>
      <td class="tg-6t3r">Nil Kıymetli Madenler Tic. A.Ş</td>
    </tr>
    <tr>
      <td class="tg-1wig" translate>infoSociety.address</td>
      <td class="tg-6t3r">MOLLA FENARİ MAH. ÇUHACI HANI SK. ÇUHACI HAN NO: 14 İÇ KAPI NO: Z30 FATİH / İSTANBUL</td>
    </tr>
    <tr>
      <td class="tg-1wig" colspan="2" translate>infoSociety.contact</td>
    </tr>
    <tr>
      <td class="tg-1wig" translate>infoSociety.phone</td>
      <td class="tg-6t3r">+90 212 520 3417</td>
    </tr>
    <tr>
      <td class="tg-1wig" translate>infoSociety.phone</td>
      <td class="tg-6t3r">+90 212 513 5657</td>
    </tr>
    <tr>
      <td class="tg-1wig" translate>infoSociety.mail</td>
      <td class="tg-6t3r">mail@nilkiymetlimadenler.com</td>
    </tr>
    <tr>
      <td class="tg-1wig" translate>infoSociety.dateOfRegistration</td>
      <td class="tg-6t3r">19/04/2018</td>
    </tr>
    <tr>
      <td class="tg-1wig" translate>infoSociety.sector</td>
      <td class="tg-6t3r" translate>infoSociety.sectorText</td>
    </tr>
    <tr>
      <td class="tg-1wig" translate>infoSociety.chairman</td>
      <td class="tg-6t3r">Muhammed Emin PÜTKÜL</td>
    </tr>
    </tbody>
  </table>
</div>
