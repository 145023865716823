<div class="content">
  <section class="who">
    <div class="col-lg-4 col-md-4" id="logo">
      <img style="width:250px" src="../../assets/img/logo2.png">
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6" id="address">
      <h5 translate>footer.address</h5>
      <p>MOLLA FENARİ MAH. ÇUHACI HANI SK. ÇUHACI HAN NO: 14 İÇ KAPI NO: Z30 FATİH / İSTANBUL</p>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6" id="phone">
      <h5 translate>footer.phone</h5>
      <p>+90 212 520 3417</p>
      <p>+90 212 513 5657</p>
    </div>
    <div style="text-align: center;color: white" class="col-md-12">
      Powered by <a style="color: #584923" href="http://www.aifasoft.com/">Aifasoft</a>
    </div>
  </section>
</div>
